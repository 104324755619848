import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "datetodisplay"
})
export class DateToDisplayPipe implements PipeTransform
{
  transform(value: string): string
  {
    if (value != null && value != undefined)
    {
      var datePart = value.match(/\d+/g),
        year = datePart[0], // get only two digits
        //month = datePart[1],
        month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][Number(datePart[1]) - 1],
        day = datePart[2];
      return day + ' ' + month + ' ' + year;
    }
    else
      return null;
  }
}


