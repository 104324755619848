import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalStateService } from './Services/global-state-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent
{
  constructor(private httpClient: HttpClient, private globalStateService: GlobalStateService)
  {
  }

  ngOnInit()
  {
    this.httpClient.get("/Home/ClearTempImages", { responseType: "text" }).subscribe(() => { });
  }
}


