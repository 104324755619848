import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../Models/user';
import { Observable } from 'rxjs';
import { Preference } from '../Models/preference';

@Injectable({ providedIn: "root" })
export class UsersService
{
  constructor(private httpClient: HttpClient)
  {
  }

  getUsers(): Observable<User[]>
  {
    return this.httpClient.get<User[]>("/Users/GetUsersExceptCandidates");
  }


  getUserByLoginID(LoginID: string): Observable<User>
  {
    return this.httpClient.get<User>("/Users/GetUserByLoginID?LoginID=" + LoginID);
  }


  insertUser(data: User): Observable<string>
  {
    return this.httpClient.post("/Users/InsertUser", data, { responseType: "text" });
  }


  updateUser(data: User): Observable<string>
  {
    return this.httpClient.post("/Users/UpdateUserProfile", data, { responseType: "text" });
  }


  UpdateUserPassword(data: any): Observable<string>
  {
    return this.httpClient.post("/Users/UpdateUserPassword", data, { responseType: "text" });
  }


  deleteUser(id: string): Observable<User>
  {
    return this.httpClient.post<User>("/Users/DeleteUser?UserID=" + id, {});
  }


  GetUserByLoginIDAndPassword(LoginID: string, Password: string)
  {
    return this.httpClient.post<User>("/Users/GetUserByLoginIDAndPassword", { LoginID: LoginID, Password: Password });
  }


  SetCurrentUser(CurrentUser)
  {
    localStorage.CurrentUser = JSON.stringify(CurrentUser);
  }


  GetCurrentUser()
  {
    if (localStorage.CurrentUser != null && localStorage.CurrentUser != undefined)
      return JSON.parse(localStorage.CurrentUser);
    else
      return null;
  }


  Logout()
  {
    localStorage.CurrentUser = null;
  }


  isCurrentUserHasPermission(systemIndex)
  {
    var currUser = (this.GetCurrentUser() == null || this.GetCurrentUser() == undefined)? {} : this.GetCurrentUser();

    var UserPermissionSystemIndexes;
    if (currUser.UserPermissionSystemIndexes == null || currUser.UserPermissionSystemIndexes == undefined)
    {
      UserPermissionSystemIndexes = [];
    }
    else
    {
      UserPermissionSystemIndexes = currUser.UserPermissionSystemIndexes;
    }
    return UserPermissionSystemIndexes.filter(temp => temp == systemIndex ).length > 0;
  }


  getGlobalSetting()
  {
    return this.httpClient.get<Preference>("/GlobalSettings/GetGlobalSetting");
  }

  UpdateGlobalSetting(preference: Preference)
  {
    return this.httpClient.post<Preference>("/GlobalSettings/UpdateGlobalSetting", preference);
  }

}



