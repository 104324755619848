import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './account-module/login-component/login.component';

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "admin", loadChildren: () => import('./admin-module/admin.module').then(m => m.AdminModule) },
  { path: "student", loadChildren: () => import('./student-module/student.module').then(m => m.StudentModule) },
  { path: "", redirectTo: "/login", pathMatch: "full" },
  { path: "**", redirectTo: "/login" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


