import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/Services/users.service';

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent
{
  // loginid: string = "principal";
  // password: string = "principal123#";

  loading: boolean = false;
  loginid: string = "";
  password: string = "";
  
  // loginid: string;
  // password: string;

  loginError: string;

  constructor(private usersService: UsersService, private router: Router)
  {
  }

  
  ngOnInit()
  {
    setTimeout(() =>
    {
      document.getElementById("username").focus();
    }, 100);
  }

  onLoginClick()
  {
    this.loading = true;
    this.usersService.GetUserByLoginIDAndPassword(this.loginid, this.password).subscribe(
      (response) =>
      {
        if (response != null)
        {
          this.loginError = null;
          this.usersService.SetCurrentUser(response);
          if (response.UserType == "Candidate")
          {
            this.router.navigate(['/student', 'dashboard']);
          }
          else if (response.UserType == "Administrator" || response.UserType == "Lecturer" || response.UserType == "Staff")
          {
            if (response.UserPermissionSystemIndexes.filter(temp => temp == 101 ).length > 0)
            {
              this.router.navigate(['/admin', 'dashboard']);
            }
            else
            {
              this.loginError = "You don't have permission to login. Please contact Principal.";
            }
          }
          else
          {
            this.router.navigate(['/admin', 'dashboard']);
          }
        }
        else
        {
          this.loginError = "Invalid Username or Password";
        }
        this.loading = false;
      },
      (error) =>
      {
        this.loginError = "Unable to connect to database";
        console.log(error);
        this.loading = false;
      }
    );
  }
}
