import { Injectable } from '@angular/core';
import { TestSummary } from '../Models/test-summary';

@Injectable({ providedIn: "root" })
export class GlobalStateService
{
  //Test Summary
  currentCandidateRegNo: string = null;
  testSummaries: TestSummary[] = [];
  currentTestSummaryIndex: number = 0;
  currentTestID: string = null;
  currentImagePath: string = null;
}


